.ios ion-title.no-right-padding {
  padding-right: 10px !important;
  padding-inline-end: 10px !important;
}

.ios ion-title.buttons-left {
  padding-left: 39px !important;
  padding-right: 39px !important;
  padding-inline-start: 39px !important;
  padding-inline-end: 39px !important;
}

ion-card img {
  width: 100%;
}
